import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorButtons,
} from "../../components/calculator"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
import { getCalculatorData } from "../../lib/store"

const log = logger.extend("calculator:start")

const { calculator } = locale

const SECTION_KEY = "start"

const REQUIRED_FIELDS = [
  "number_of_employees",
  "number_of_weeks_to_plan_for",
  "number_of_days_per_week_open",
]

// Sep 1
const FLU_SEASON_START = [8, 1]
// Apr 30
const FLU_SEASON_END = [3, 30]

const inFluSeason = () => {
  const currentMonth = new Date().getMonth()
  return (
    currentMonth <= FLU_SEASON_END[0] || currentMonth >= FLU_SEASON_START[0]
  )
}

const endOfFluSeason = () => {
  const today = new Date()
  const currentMonth = today.getMonth()
  let endOfFluSeasonYear = today.getFullYear()

  if (currentMonth >= FLU_SEASON_START[0]) {
    endOfFluSeasonYear++
  }
  return new Date(endOfFluSeasonYear, ...FLU_SEASON_END)
}

const weeksUntil = endDate => {
  const msBetween = endDate.getTime() - new Date().getTime()
  return Math.ceil(msBetween / (1000 * 60 * 60 * 24 * 7))
}

const endOfYear = () => {
  const endOfYear = new Date()
  endOfYear.setMonth(11)
  endOfYear.setDate(31)
  return endOfYear
}

const CalculatorStart = () => {
  const allFields = getCalculatorData()
  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: allFields || {},
  })
  const [hasRequiredFieldValues, setHasRequiredFieldValues] = useState(false)

  const onSubmit = generateOnSubmit(SECTION_KEY)
  const hasErrors = !!Object.keys(errors).length
  const watchedValues = watch()

  if (hasErrors) {
    log("Form errors:", errors)
  }

  const weeksUntilFluSeasonEnd = weeksUntil(endOfFluSeason())
  const weeksUntilEndOfYear = weeksUntil(endOfYear())

  // Track when required fields are set or not to disable nav.
  // Oportunity for refactor here, but this is a unique step where we need
  // to do this validation.
  useEffect(() => {
    const allRequiredValuesSet = REQUIRED_FIELDS.every(
      key => Number(watchedValues[key]) > 0
    )
    setHasRequiredFieldValues(allRequiredValuesSet)
    log("All required values set", allRequiredValuesSet)
  }, [watchedValues])

  return (
    <Layout>
      <SEO title={calculator.title} />
      <CalculatorLayout
        sectionKey={SECTION_KEY}
        disableNav={!hasRequiredFieldValues}
      >
        <div className="prose max-w-none mb-10">
          <h2>Baseline PPE Need Assumptions</h2>
          <p>
            To the best of your ability, enter estimations about your business.
            The calculator will then incorporate these into a projection for
            your needs:
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="container max-w-md">
          <Fieldset
            errorMessage={
              <ErrorMessage errors={errors} name="number_of_employees" />
            }
          >
            <CalculatorInput
              register={register({
                min: {
                  value: 1,
                  message: "You must have at least 1 employee",
                },
                required: "This field is required",
              })}
              type="number"
              name="number_of_employees"
              className="w-16 mr-5"
            />
            <label htmlFor="number_of_employees">
              # of employees
              <div className="text-gray-500 text-sm">
                We recommend entering the number of people you employ (even if
                part-time) instead of the full time equivalent (FTE) number.
              </div>
            </label>
          </Fieldset>

          <Fieldset
            errorMessage={
              <ErrorMessage
                errors={errors}
                name="number_of_customers_per_week"
              />
            }
          >
            <CalculatorInput
              register={register({ min: 0 })}
              type="number"
              name="number_of_customers_per_week"
              className="w-16 mr-5"
            />
            <label htmlFor="number_of_customers_per_week" className="flex-grow">
              # of customers per week
            </label>
          </Fieldset>

          <Fieldset
            errorMessage={
              <ErrorMessage
                errors={errors}
                name="number_of_weeks_to_plan_for"
              />
            }
          >
            <CalculatorInput
              register={register({
                min: {
                  value: 1,
                  message: "You must plan for at least 1 week",
                },
                required: "This field is required",
              })}
              type="number"
              name="number_of_weeks_to_plan_for"
              className="w-16 mr-5"
            />
            <label htmlFor="number_of_weeks_to_plan_for">
              # of weeks you would like to stockpile for
              <div className="text-gray-500 text-sm">
                {inFluSeason() ? (
                  <>
                    {weeksUntilFluSeasonEnd} weeks until end of flu season.
                    <br />
                  </>
                ) : null}{" "}
                {weeksUntilEndOfYear} weeks until end of year.
              </div>
            </label>
          </Fieldset>

          <Fieldset
            errorMessage={
              <ErrorMessage
                errors={errors}
                name="number_of_days_per_week_open"
              />
            }
          >
            <CalculatorInput
              register={register({
                min: {
                  value: 1,
                  message: "Must be between 1 and 7",
                },
                max: {
                  value: 7,
                  message: "Must be between 1 and 7",
                },
                required: "This field is required",
              })}
              type="number"
              name="number_of_days_per_week_open"
              className="w-16 mr-5"
            />
            <label htmlFor="number_of_days_per_week_open">
              # of days per week is your business open
            </label>
          </Fieldset>

          <CalculatorButtons
            register={register}
            sectionKey={SECTION_KEY}
            showShoppingListNote={false}
            className="items-center"
            nextButtonLabel={"Begin!"}
          />
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorStart
